/* PhoneInputStyles.css */

.PhoneInput {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
  width: 100%;
  display: flex;
  gap: 3px;
}

.PhoneInput--focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.PhoneInput input {
  border: none;
  outline: none;
  width: 100%;
}
.PhoneInputCountrySelect {
  max-width: 110px;
}

.PhoneInputCountryIconImg {
  display: none;
}

/* Zmienne CSS dla zmiany wyglądu flagi, strzałki itd. */
:root {
  --PhoneInputCountryFlag-height: 100px; /* Wysokość flagi */
  --PhoneInputCountryFlag-borderColor: #d9d9d9; /* Kolor obramowania flagi */
  --PhoneInputCountrySelectArrow-color: #595959; /* Kolor strzałki */
  --PhoneInputCountrySelectArrow-opacity: 1; /* Nieprzezroczystość strzałki */
  --PhoneInput-color--focus: #40a9ff; /* Kolor dla stanu :focus */
}
