@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
:root {
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  margin: 0;
}

body {
  margin: 0;
  font-family: "Roboto", "Lato", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 650;
}

.category-container::-webkit-scrollbar {
  display: none; /* dla WebKit */
}

.category-container {
  -ms-overflow-style: none; /* dla IE i Edge */
  scrollbar-width: none; /* dla Firefox */
}

.button-animate {
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    transform 0.2s ease;
}

.button-animate.pressed {
  transform: scale(0.95);
}

.grayscale {
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out; /* Dodajemy animację dla filtru */
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
.bounce {
  animation: bounce 3s infinite;
}
